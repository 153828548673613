import { GspContext } from '~/stores/gsp';
import {
  createLoaderDataHook,
  gspLoader,
  json,
  redirect,
} from '~/utils/routing';

type ShippingClaimOverrideAction = NonNullable<
  NonNullable<GspContext['order']>['shippingClaimReasonOverrides']
>[number];
export type LoaderData = {
  shippingClaimAction: ShippingClaimOverrideAction;
};

export default gspLoader(({ params: { store = '' }, context }) => {
  if (!context.order || !context.reason) {
    return redirect(`/${store}`);
  }

  const {
    order: { shippingClaimReasonOverrides },
  } = context;

  const shippingClaimAction = shippingClaimReasonOverrides?.find(
    (override) => override.reason === context.reason,
  );

  if (!shippingClaimAction) {
    return redirect(`/${store}`);
  }

  return json<LoaderData>({
    shippingClaimAction,
  });
});

export const useShippingIssueLoaderData = createLoaderDataHook<LoaderData>();

import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { CrewStore } from '~/stores/crew';
import { error } from '~/utils/routing';
import { defaultTheme, setTheme } from '~/utils/theme';

const getTheme = (store?: string) =>
  store ?
    CrewStore.initAsync(store).then(() => CrewStore.settings.theme)
  : Promise.resolve(defaultTheme);

const removeSplashScreen = () => {
  const body = document.getElementById('crew');

  if (!body) {
    throw new Error('Body not found');
  }

  body.classList.add('ready');
};

export default async ({ params: { store } }: LoaderFunctionArgs) =>
  getTheme(store)
    .then(setTheme)
    .then(() => ({ ok: true }))
    .catch((cause) =>
      // * If the error is a RangeError from the Crew Store, redirect to the base route
      cause instanceof RangeError ?
        redirect('/')
      : error(
          cause instanceof Error ? cause : (
            new Error('Failed to load theme', { cause })
          ),
        ),
    )
    .finally(removeSplashScreen);

import {
  faDhl,
  faFedex,
  faUps,
  faUsps,
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowRightArrowLeft,
  faArrowsRotate,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleInfo,
  faExclamationCircle,
  faMagnifyingGlass,
  faTrash,
  faTruck,
  faXmark,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCircleCheck as faCircleCheckSolid,
  faCircleInfo as faCircleInfoSolid,
  faExclamationCircle as faExclamationCircleSolid,
  faExclamationTriangle as faExclamationTriangleSolid,
  faImage as faImageSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';

const icons = {
  ArrowRightArrowLeft: faArrowRightArrowLeft,
  ArrowsRotate: faArrowsRotate,
  Check: faCheck,
  CheckCircle: faCheckCircle,
  CheckCircleSolid: faCircleCheckSolid,
  ChevronDown: faChevronDown,
  ChevronLeft: faChevronLeft,
  ChevronRight: faChevronRight,
  ChevronUp: faChevronUp,
  ExclamationCircle: faExclamationCircle,
  ExclamationCircleSolid: faExclamationCircleSolid,
  ExclamationTriangleSolid: faExclamationTriangleSolid,
  ImageSolid: faImageSolid,
  InfoCircle: faCircleInfo,
  InfoCircleSolid: faCircleInfoSolid,
  MagnifyingGlass: faMagnifyingGlass,
  Trash: faTrash,
  Truck: faTruck,
  XMark: faXmark,
  DHL: faDhl,
  FedEx: faFedex,
  UPS: faUps,
  USPS: faUsps,
} as const;

type IconProps = {
  className?: string;
  icon: keyof typeof icons;
  title?: string;
};

export default function Icon({ className, icon, title }: IconProps) {
  return (
    <FontAwesomeIcon
      icon={icons[icon]}
      className={twMerge('size-5', className)}
      title={title}
    />
  );
}

const getCarrierIcon = (carrierName: string) =>
  match(carrierName.toLowerCase())
    .returnType<IconProps['icon']>()
    .with('dhl', () => 'DHL')
    .with('fedex', () => 'FedEx')
    .with('ups', () => 'UPS')
    .with('usps', () => 'USPS')
    .otherwise(() => 'Truck');

export function CarrierIcon({
  carrier,
  className,
}: {
  carrier: string;
  className?: string;
}) {
  return <Icon icon={getCarrierIcon(carrier)} className={className} />;
}

import { z } from 'zod';
import { MediaType } from './constants';

export const determineFileType = (file: File): MediaType => {
  const type = file.type.split('/')[0];
  const parsedType = z.nativeEnum(MediaType).safeParse(type);

  if (!parsedType.success) {
    // The media type is not supported -- so what type is it? The enum is pretty exhaustive.
    throw new Error(`Unsupported file ${file.name} type: ${type}.`);
  }

  return parsedType.data;
};

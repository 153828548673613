import { GspState } from '~/stores/gsp';
import { GspClaimReason, GspReasonLabels } from '~/utils/constants';
import {
  createLoaderDataHook,
  gspLoader,
  json,
  redirect,
} from '~/utils/routing';

export type LoaderData = {
  reasons: { value: GspClaimReason; label: string }[];
  selectedReason: GspState['reason'] | null;
};

export default gspLoader(({ params: { store = '' }, context }) =>
  !context.order ?
    redirect(`/${store}`)
  : json<LoaderData>({
      reasons: context.order.shippingClaimReasons.map((reason) => ({
        value: reason,
        label: GspReasonLabels[reason],
      })),
      selectedReason: context.reason,
    }),
);

export const useReasonLoaderData = createLoaderDataHook<LoaderData>();

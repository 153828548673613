import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const {
  VITE_NODE_ENV: environment,
  VITE_SENTRY_DSN: dsn,
  VITE_SENTRY_APPLICATION_KEY: applicationKey,
  VITE_BUILD_SHA: release,
} = import.meta.env;

if (environment === 'prod') {
  const integrations = [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    // capture react errors
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      minReplayDuration: 15000,
      networkDetailAllowUrls: [
        window.location.origin,
        'api.corso.com',
        'api.stg.corso.com',
      ],
      networkCaptureBodies: true,
    }),
    // This integration captures errors on failed requests from Fetch and XHR and attaches request and response information.
    Sentry.httpClientIntegration(),
  ];

  if (applicationKey) {
    integrations.push(
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: [applicationKey],
        behaviour: 'drop-error-if-exclusively-contains-third-party-frames',
      }),
    );
  }

  Sentry.init({
    environment,
    dsn,
    normalizeDepth: Infinity,
    attachStacktrace: true,
    integrations,
    // This option is required for capturing headers and cookies.
    sendDefaultPii: true,
    tracesSampleRate: 0.2,
    tracePropagationTargets: [/^https:\/\/api\.corso\.com/],
    ignoreErrors: [
      'Object Not Found Matching Id', // https://github.com/getsentry/sentry-javascript/issues/3440
      'Invalid call to runtime.sendMessage()', // extension error (https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/API/runtime/sendMessage)
    ],
    release,
    // replay integration configuration
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.5,
  });

  Sentry.setTag(`isEmbedded`, window.parent !== window);
}

import { ZodSchema } from 'zod';
import { stringToJSONSchema } from './schemas';

/**
 * A simple client to store and retrieve data from sessionStorage
 * Some browsers disable sessionStorage based on security settings
 * This will check if sessionStorage is enabled before trying to use it
 * If it is not enabled it will not do anything
 * */
export const createSessionStorageClient = <T = unknown>(
  key: string,
  schema?: ZodSchema<T>,
) =>
  Object.freeze({
    store: (data: T) => {
      try {
        sessionStorage.setItem(key, JSON.stringify(data));
      } catch {
        // gracefully do nothing -- sessionStorage is full or disabled
      }
    },

    retrieve: () => {
      try {
        const data = sessionStorage.getItem(key) ?? '';
        return schema ?
            stringToJSONSchema.pipe(schema).parse(data)
          : (stringToJSONSchema.parse(data) as T); // TODO come back to this casting
      } catch (e) {
        return null;
      }
    },

    clear: () => {
      try {
        sessionStorage.removeItem(key);
      } catch {
        // gracefully do nothing -- sessionStorage is disabled
      }
    },
  });

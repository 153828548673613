import {
  forwardRef,
  KeyboardEvent,
  Ref,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { match } from 'ts-pattern';
import Button from '~/components/button';
import Icon from '~/components/icon';
import {
  Modal,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '~/components/modal';
import Spinner from '~/components/spinner';
import api from '~/utils/api';
import { uniqueId } from '~/utils/compute';
import { MediaType } from '~/utils/constants';
import { determineFileType } from '~/utils/file';
import Field from './field';

type UploadFilesParams = Parameters<typeof api.uploadFile>[0];
type APIUploadResult = Awaited<ReturnType<typeof api.uploadFile>>;
type MediaAsset = Extract<APIUploadResult, { success: true }>['data'];

type UploadedAsset = MediaAsset & { id: string; kind: 'uploaded' };
type PreviewAsset = MediaAsset & {
  id: string;
  kind: 'preview';
  file: File;
  hasError?: boolean;
};

type Asset = UploadedAsset | PreviewAsset;

type AssetDisplayProps = {
  asset: Asset;
  onRemove: (asset: Asset) => void;
  onRetry: (asset: PreviewAsset) => void;
};

function AssetDisplay({ asset, onRemove, onRetry }: AssetDisplayProps) {
  const [assetSrc, setAssetSrc] = useState(asset.src);
  const [showConfirm, setShowConfirm] = useState(false);
  const closeConfirm = () => setShowConfirm(false);
  const removeAsset = () => {
    onRemove(asset);
    closeConfirm();
  };

  useEffect(() => {
    const updateSrc = (src: string) => {
      setAssetSrc((prev) => {
        // clean up previous object URL to prevent memory leaks after updating state
        setTimeout(() => URL.revokeObjectURL(prev), 100);
        return src;
      });
    };

    if (assetSrc === asset.src) return;

    if (asset.type === 'image') {
      const img = new Image();
      img.onload = () => {
        updateSrc(asset.src);
      };
      img.src = asset.src;
    }

    if (asset.type === 'video') {
      const video = document.createElement('video');
      video.onloadeddata = () => {
        updateSrc(asset.src);
      };
      video.src = asset.src;
    }
  }, [asset, assetSrc]);

  return (
    <>
      <figure className="relative aspect-square w-full min-w-[80px] rounded-lg border border-gray-300">
        {match(asset.type)
          .with(MediaType.video, () => (
            <video
              controls
              className="h-full w-full rounded-lg object-cover"
              src={assetSrc}
              muted
            />
          ))
          .with(MediaType.image, () => (
            <img
              className="h-full w-full rounded-lg object-cover"
              src={assetSrc}
              alt={asset.name}
            />
          ))
          .otherwise(() => (
            <img
              className="h-full w-full rounded-lg object-cover"
              src="https://cdn.corso.com/img/image-not-available.jpeg"
              alt={asset.name}
            />
          ))}

        <div
          className={twMerge(
            'group absolute inset-0 flex items-start justify-end p-1.5',
            asset.kind === 'preview' && 'bg-white/40',
          )}
        >
          {match(asset)
            .with({ kind: 'preview', hasError: true }, (previewAsset) => (
              <div className="relative flex-grow-0 transition-[flex-grow] duration-300 group-hover:flex-grow">
                <div
                  role="alert"
                  aria-label="Upload Alert"
                  aria-describedby="upload-alert"
                  className="pointer-events-none absolute right-0 top-0 opacity-100 transition-opacity duration-150 group-hover:opacity-0"
                >
                  <span id="upload-alert" className="hidden">
                    Failed to upload ${previewAsset.name}. Click to retry.
                  </span>
                  <Icon
                    icon="ExclamationCircleSolid"
                    className="size-8 rounded-full bg-white text-error"
                  />
                </div>
                <Button
                  variant="filled"
                  className="absolute right-0 top-0 flex w-full items-center justify-between bg-black/90 text-white opacity-0 transition-opacity duration-150 group-hover:opacity-100"
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    onRetry(previewAsset);
                  }}
                >
                  <span className="opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                    Retry
                  </span>
                  <Icon icon="ArrowsRotate" className="size-4" />
                </Button>
              </div>
            ))
            .with({ kind: 'preview' }, (previewAsset) => (
              <Spinner
                className="size-7 fill-black"
                description={`Uploading ${previewAsset.name}`}
              />
            ))
            .with({ kind: 'uploaded' }, (uploadedAsset) => (
              <Button
                variant="text"
                className="rounded-full bg-black/60 p-1 text-white transition group-hover:bg-black/90"
                onClick={() => setShowConfirm(true)}
              >
                <Icon icon="XMark" title={`Remove ${uploadedAsset.name}`} />
              </Button>
            ))
            .otherwise(() => null)}
        </div>
        <figcaption className="sr-only">{asset.name}</figcaption>
      </figure>

      <Modal open={showConfirm} onClose={closeConfirm}>
        <ModalHeader>
          <ModalTitle>Do you want to remove this file?</ModalTitle>
        </ModalHeader>
        <p className="text-sm">
          Removing the file will delete this file from your request.
        </p>
        <ModalFooter>
          <Button variant="outlined" onClick={closeConfirm}>
            Keep File
          </Button>
          <Button onClick={removeAsset}>Remove File</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

type FileInputProps = {
  required?: boolean;
  onChange: (files: FileList) => void;
  accept?: HTMLInputElement['accept'];
  ariaDescribedby?: string;
};

const FileInput = forwardRef(
  (
    { required, onChange, accept = '*', ariaDescribedby }: FileInputProps,
    ref: Ref<{ clear: () => void }>,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const onClick = () => {
      inputRef.current?.click();
    };
    const onKeyUp = ({ key }: KeyboardEvent) => {
      if (key === 'Enter' || key === ' ') {
        inputRef.current?.click();
      }
    };

    useImperativeHandle(ref, () => ({
      clear: () => {
        if (inputRef.current) {
          inputRef.current.value = '';
          inputRef.current.files = null;
        }
      },
    }));

    return (
      <>
        <Button
          className="w-full sm:max-w-max"
          variant="outlined"
          onClick={onClick}
          onKeyUp={onKeyUp}
          aria-describedby={ariaDescribedby}
        >
          <Icon icon="ImageSolid" className="size-6" />
          <span className="ml-3">Upload</span>
          {required && <span className="sr-only">required</span>}
        </Button>
        <input
          data-testid="fileInput"
          multiple
          hidden
          ref={inputRef}
          type="file"
          accept={accept}
          onChange={(event) => {
            if (event.target.files) {
              onChange(event.target.files);
            }
          }}
        />
      </>
    );
  },
);

type AcceptType =
  | 'image/jpeg'
  | 'image/png'
  | 'image/webp'
  | 'video/mp4'
  | 'video/webm'
  | 'application/pdf';

const uploadFile =
  (params: {
    storefrontId: UploadFilesParams['params']['storefrontId'];
    destination: UploadFilesParams['query']['destination'];
    onSuccess: (params: {
      asset: PreviewAsset;
      result: Extract<APIUploadResult, { success: true }>;
    }) => void;
    onError: (params: {
      asset: PreviewAsset;
      result: Extract<APIUploadResult, { success: false }>;
    }) => void;
  }) =>
  (asset: PreviewAsset) =>
    api
      .uploadFile({
        params: { storefrontId: params.storefrontId },
        query: { destination: params.destination },
        body: asset.file,
      })
      .then((result) => {
        if (result.success) {
          params.onSuccess({ asset, result });
        } else {
          params.onError({ asset, result });
        }
      })
      .catch((e) => {
        params.onError({
          asset,
          result: {
            success: false,
            error: new Error('Failed to upload', { cause: e }),
          },
        });
      });

type Props = {
  className?: string;
  name: string;
  storefrontId: UploadFilesParams['params']['storefrontId'];
  destination: UploadFilesParams['query']['destination'];
  requiredCount?: number;
  defaultValue?: MediaAsset[];
  description?: string;
  accept?: AcceptType[];
};

export default function FileUploader({
  className,
  name,
  storefrontId,
  destination,
  requiredCount = 0,
  defaultValue = [],
  description,
  accept = [
    'image/jpeg',
    'image/png',
    'image/webp',
    'video/mp4',
    'video/webm',
    'application/pdf',
  ],
}: Props) {
  const [assets, setAssets] = useState<Asset[]>(
    defaultValue.map((asset) => ({
      ...asset,
      id: uniqueId(),
      kind: 'uploaded',
    })),
  );
  const fileInputRef = useRef<{ clear: () => void }>(null);
  const descriptionId = useId();
  const acceptId = useId();

  const onUploadSuccess = ({
    asset,
    result,
  }: {
    asset: PreviewAsset;
    result: Extract<APIUploadResult, { success: true }>;
  }) => {
    setAssets((prev) => {
      const next = [...prev];
      const index = next.findIndex((a) => a.id === asset.id);

      next[index] = {
        ...result.data,
        id: asset.id,
        kind: 'uploaded',
      } satisfies UploadedAsset;

      return next;
    });
  };

  const onUploadError = ({
    asset,
  }: {
    asset: PreviewAsset;
    result: Extract<APIUploadResult, { success: false }>;
  }) => {
    setAssets((prev) => {
      const next = [...prev];
      const index = next.findIndex((a) => a.id === asset.id);

      next[index] = {
        ...asset,
        hasError: true,
      };

      return next;
    });
  };

  const uploadAsset = uploadFile({
    storefrontId,
    destination,
    onSuccess: onUploadSuccess,
    onError: onUploadError,
  });

  const onUpload = (fileList: FileList) => {
    const files = Array.from(fileList);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- the uploadAsset function will handle the promise just need to clear the file input after
    Promise.allSettled(
      files.map((file) => {
        const asset = {
          id: uniqueId(),
          name: file.name,
          src: URL.createObjectURL(file),
          type: determineFileType(file),
          kind: 'preview',
          file,
        } satisfies PreviewAsset;

        setAssets((prev) => [...prev, asset]);

        return uploadAsset(asset);
      }),
    ).finally(() => {
      // resets the file input after upload
      fileInputRef.current?.clear();
    });
  };

  const removeAsset = (asset: Asset) => {
    setAssets((prev) => prev.filter((a) => a.id !== asset.id));
  };

  return (
    <div role="group" className={twMerge('flex flex-col gap-2', className)}>
      <FileInput
        ref={fileInputRef}
        onChange={onUpload}
        required={requiredCount > 0}
        accept={accept.join(',')}
        ariaDescribedby={[descriptionId, acceptId].join(' ')}
      />

      {(requiredCount > 0 || description) && (
        <Field.HelpText
          data-testid="supportingText"
          id={acceptId}
          className="text-gray-500"
        >
          {requiredCount > 0 && (
            <span>
              {requiredCount === 1 ?
                'At least one file is required.'
              : `A minimum of ${requiredCount} files required.`}
            </span>
          )}
          {description && <span>{description}</span>}
        </Field.HelpText>
      )}

      {assets.length > 0 && (
        <ul className="grid grid-cols-2 gap-4 sm:grid-cols-4">
          {assets.map((asset) => (
            <li key={asset.id}>
              <AssetDisplay
                asset={asset}
                onRemove={removeAsset}
                onRetry={(retryAsset) => {
                  setAssets((prev) => {
                    const next = [...prev];
                    const index = next.findIndex((a) => a.id === retryAsset.id);

                    next[index] = {
                      ...retryAsset,
                      hasError: false,
                    };

                    return next;
                  });

                  // eslint-disable-next-line @typescript-eslint/no-floating-promises -- the onerror handler will handle the error
                  uploadAsset(retryAsset);
                }}
              />
            </li>
          ))}
        </ul>
      )}

      {assets
        .filter((a) => a.kind === 'uploaded')
        .map(({ id, kind, ...asset }) => (
          <input
            key={id}
            hidden
            name={name}
            defaultValue={JSON.stringify(asset)}
          />
        ))}
    </div>
  );
}

import api from '~/utils/api';
import { crewStateSchema } from '~/utils/schemas';
import { createSessionStorageClient } from '~/utils/session-storage-client';
import { Address, CrewContext, CrewState } from '~/utils/types';

let state: CrewState | null = null;
let settingsRequest: ReturnType<typeof api.fetchSettings> | null = null;

const crewStorage = createSessionStorageClient(
  'corso-crew-state',
  crewStateSchema,
);

const notifyChange = (newState: CrewState) => {
  crewStorage.store(newState);
};

const createInitialState = (settings: CrewState['settings']): CrewState => ({
  settings,
  claim: null,
  draftClaim: null,
  lineItemClaims: [],
  order: null,
  address: null,
  returnMethod: null,
  toCustomerRate: null,
});

const getter = <T extends keyof CrewState>(key: T) => {
  if (!state) {
    throw new Error('State not initialized');
  }

  return state[key];
};

const assign = <K extends keyof CrewState, V extends CrewState[K]>(
  key: K,
  value: V,
) => {
  if (!state) {
    throw new Error('State not initialized');
  }

  state[key] = value;
  notifyChange(state);

  return value;
};

export const CrewStore: CrewContext = {
  get claim() {
    return getter('claim');
  },

  get draftClaim() {
    return getter('draftClaim');
  },

  get lineItemClaims() {
    return getter('lineItemClaims');
  },

  get order() {
    return getter('order');
  },

  get settings() {
    return getter('settings');
  },

  get address() {
    return getter('address');
  },

  get returnMethod() {
    return getter('returnMethod');
  },

  get toCustomerRate() {
    return getter('toCustomerRate');
  },

  setOrder: (order: CrewState['order']) => assign('order', order),

  setAddress: (address: Address) => assign('address', address),

  setClaim: (claim: NonNullable<CrewState['claim']>) => assign('claim', claim),

  setDraftClaim: (draft) => assign('draftClaim', draft),

  clearDraftClaim: () => assign('draftClaim', null),

  upsertLineItemClaim: (lineItemClaim) => {
    const lineItemClaims = getter('lineItemClaims');

    const index = lineItemClaims.findIndex(
      (claim) => claim.id === lineItemClaim.id,
    );

    if (index !== -1) {
      lineItemClaims.splice(index, 1, lineItemClaim);
    } else {
      lineItemClaims.push(lineItemClaim);
    }

    assign('lineItemClaims', [...lineItemClaims]);

    return lineItemClaim;
  },

  getLineItemClaim: (id) => {
    const lineItemClaims = getter('lineItemClaims');

    return lineItemClaims.find((claim) => claim.id === id);
  },

  removeLineItemClaim: (id) => {
    const lineItemClaims = getter('lineItemClaims');
    const index = lineItemClaims.findIndex((claim) => claim.id === id);

    if (index === -1) {
      return false;
    }
    lineItemClaims.splice(index, 1);

    assign('lineItemClaims', [...lineItemClaims]);

    return true;
  },

  setReturnMethod: (shipmentMethod: NonNullable<CrewState['returnMethod']>) =>
    assign('returnMethod', shipmentMethod),

  setToCustomerRate: (rate: NonNullable<CrewState['toCustomerRate']>) =>
    assign('toCustomerRate', rate),

  init: (settings: CrewState['settings']) => {
    // state is already initialized just update settings -- really for testing
    if (state) {
      state.settings = settings;
      return;
    }
    // restore from session storage
    const parsed = crewStorage.retrieve();

    state =
      parsed ?
        { ...parsed, settings }
        // no state in session storage -- create new
      : createInitialState(settings);

    notifyChange(state);
  },

  initAsync: async (store: string) => {
    // state is already initialized
    if (state) {
      return;
    }

    // * These are first level paths in the app that should not be used as store names for instance /order/order/:orderId
    // really don't like having this here vs with the router, but it is the central path for the app
    const invalidKeywords = [
      'order',
      'claim',
      'inactive',
      'register',
      'reorder',
    ];

    if (invalidKeywords.includes(store)) {
      throw new RangeError('The store name must not be a reserved keyword', {
        cause: store,
      });
    }
    // prevent multiple requests such as in parallel loaders initializing the store
    const request =
      settingsRequest ?? api.fetchSettings({ params: { slug: store } });
    settingsRequest = request;

    const settings = await request;

    CrewStore.init(settings);
  },
  reset: () => {
    state = createInitialState(CrewStore.settings);
    notifyChange(state);
  },

  //! This is a dangerous method that should only be used in testing as it clears EVERYTHING
  dangerouslyReset: () => {
    state = null;
    crewStorage.clear();
  },

  clearLineItemClaims: () => {
    state = {
      ...createInitialState(CrewStore.settings),
      order: CrewStore.order,
    };

    notifyChange(state);
  },
} as const;

import { GspState } from '~/stores/gsp';
import api from '~/utils/api';
import { GspClaimReason } from '~/utils/constants';
import {
  createLoaderDataHook,
  gspLoader,
  json,
  redirect,
} from '~/utils/routing';

type UploadFilesParams = Parameters<typeof api.uploadFile>[0];

export type LoaderData = {
  uploadFileInfo?: {
    storefrontId: UploadFilesParams['params']['storefrontId'];
    destination: UploadFilesParams['query']['destination'];
  };
  requiredImgUploadCount: number;
} & GspState['meta'];

export default gspLoader(({ params: { store = '' }, context }) => {
  if (!context.order || !context.reason) {
    return redirect(`/${store}`);
  }

  const requiredImgUploadCount =
    (
      context.reason === GspClaimReason.damaged &&
      context.order.isShippingClaimImgRequired
    ) ?
      1
    : 0;

  const uploadFileInfo =
    context.reason === GspClaimReason.damaged ?
      {
        storefrontId: context.order.storefrontId,
        destination: 'gspClaimImg' as const,
      }
    : undefined;

  return json<LoaderData>({
    uploadFileInfo,
    requiredImgUploadCount,
    ...context.meta,
  });
});

export const useMetaLoaderData = createLoaderDataHook<LoaderData>();

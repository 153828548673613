import { z } from 'zod';
import { zfd } from 'zod-form-data';
import {
  createActionResultHook,
  error,
  gspAction,
  json,
  redirect,
} from '~/utils/routing';
import { mediaAssetSchema, stringToJSONSchema } from '~/utils/schemas';
import { ActionResult } from '~/utils/types';

const schema = zfd.formData(
  z.object({
    noteFromCustomer: zfd.text(z.string().optional()),
    assets: zfd.repeatableOfType(stringToJSONSchema.pipe(mediaAssetSchema)),
    requiredImgUploadCount: zfd.numeric(),
  }),
);

export default gspAction(({ context, formData }) => {
  if (!context.order) {
    return error(new Error('Context Error: Order not found'));
  }

  const results = schema.safeParse(formData);

  if (!results.success) {
    const formInfo = Object.fromEntries([...formData.entries()]) as Record<
      string,
      unknown
    >;

    return error(
      new Error('Malformed form data', {
        cause: {
          error: results.error,
          formInfo,
        },
      }),
    );
  }

  const { noteFromCustomer, assets, requiredImgUploadCount } = results.data;

  const assetsRequired = requiredImgUploadCount > 0;

  if (assetsRequired && assets.length < requiredImgUploadCount) {
    return json<ActionResult>({
      ok: false,
      message: `A minimum of ${requiredImgUploadCount} media files are required`,
    });
  }

  context.setMeta({
    noteFromCustomer,
    assets,
  });

  return redirect('../../address');
});

export const useMetaActionResult = createActionResultHook<ActionResult>();

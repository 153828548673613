import { CrewUi } from 'corso-types';
import { RegistrationContext } from '~/stores/registrations';
import api from '~/utils/api';
import { addBreadcrumb } from '~/utils/observability';
import {
  createLoaderDataHook,
  json,
  registrationLoader,
} from '~/utils/routing';

type UploadFilesParams = Parameters<typeof api.uploadFile>[0];

export type RegisterLoaderData = {
  shouldCollectProofOfPurchase: boolean;
  shouldCollectAddress: boolean;
  referrer?: string;
  storeSalesChannels: RegistrationContext['settings']['registrations']['storeSalesChannels'];
  customFields: RegistrationContext['settings']['registrations']['customFields'];
  draft: RegistrationContext['draftRegistration'];
  uploadFileInfo: {
    storefrontId: UploadFilesParams['params']['storefrontId'];
    destination: UploadFilesParams['query']['destination'];
  };
};

export default registrationLoader(({ context, request }) => {
  const {
    shouldCollectProofOfPurchase,
    storeSalesChannels,
    shouldCollectAddress,
    customFields,
  } = context.settings.registrations;

  const referrer = new URL(request.url).searchParams.get(
    CrewUi.REF_QUERY_PARAM,
  );

  // Temporary log to debug store sales channels malformation error
  addBreadcrumb({
    message: 'Registration Loader Store Sales Channels',
    level: 'info',
    data: {
      storeSalesChannels,
      defaultValue: context.draftRegistration?.storeSalesChannel,
    },
  });

  return json<RegisterLoaderData>({
    customFields,
    shouldCollectProofOfPurchase,
    storeSalesChannels,
    shouldCollectAddress,
    referrer: referrer ?? undefined,
    draft: context.draftRegistration,
    uploadFileInfo: {
      storefrontId: context.settings.storefrontId,
      destination: 'crewProofOfPurchase',
    },
  });
});

export const useRegisterData = createLoaderDataHook<RegisterLoaderData>();

import { ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Icon from './icon';

type OpenRender = ReactNode | ((open: boolean) => ReactNode);

function render(openRender: OpenRender, open: boolean) {
  return typeof openRender === 'function' ? openRender(open) : openRender;
}

export default function Disclosure({
  renderSummary: summary,
  defaultOpen = false,
  children,
  className,
}: {
  renderSummary: OpenRender;
  defaultOpen?: boolean;
  children?: OpenRender;
  className?: string;
}) {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <details
      className={twMerge('bg-white', className)}
      open={open}
      onToggle={(event) => setOpen(event.currentTarget.open)}
    >
      <summary
        role="button"
        className="flex cursor-pointer list-none items-center gap-2 rounded marker:hidden focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary [&::-webkit-details-marker]:hidden"
      >
        {render(summary, open)}
        <span className="ml-auto text-sm text-primary-on-container hover:underline">
          {open ?
            <Icon icon="XMark" title="Close" />
          : 'View'}
        </span>
      </summary>
      <div>{render(children, open)}</div>
    </details>
  );
}

import { z } from 'zod';
import {
  draftRegistrationSchema,
  registrationSchema,
  settingsSchema,
} from '~/utils/schemas';
import { createSessionStorageClient } from '~/utils/session-storage-client';
import { Simplify } from '~/utils/types';

type DraftRegistration = z.infer<typeof draftRegistrationSchema>;

const registrationStateSchema = z.object({
  draftRegistration: draftRegistrationSchema.nullable(),
  registration: registrationSchema.nullable(),
  settings: settingsSchema,
});
export type RegistrationState = z.infer<typeof registrationStateSchema>;
export type RegistrationContext = Readonly<
  Simplify<
    RegistrationState & {
      setDraftRegistration: (
        registration: DraftRegistration,
      ) => DraftRegistration;
      clearDraftRegistration: () => void;
      setRegistration: (
        registration: NonNullable<RegistrationState['registration']>,
      ) => NonNullable<RegistrationState['registration']>;

      init: (settings: RegistrationState['settings']) => void;
    }
  >
>;

const registrationStorage = createSessionStorageClient(
  'corso-registration-state',
  registrationStateSchema,
);

let state: RegistrationState | null = registrationStorage.retrieve();

const notifyChange = (newState: RegistrationState) => {
  registrationStorage.store(newState);
};

const getter = <T extends keyof RegistrationState>(key: T) => {
  if (!state) {
    throw new Error('State not initialized');
  }

  return state[key];
};

const createInitialState = (
  settings: RegistrationState['settings'],
): RegistrationState => ({
  settings,
  draftRegistration: null,
  registration: null,
});

const assign = <
  K extends keyof RegistrationState,
  V extends RegistrationState[K],
>(
  key: K,
  value: V,
) => {
  if (!state) {
    throw new Error('State not initialized');
  }

  state[key] = value;
  notifyChange(state);

  return value;
};

export const RegistrationStore: RegistrationContext = {
  get settings() {
    return getter('settings');
  },

  get registration() {
    return getter('registration');
  },

  get draftRegistration() {
    return getter('draftRegistration');
  },

  setRegistration: (
    registration: NonNullable<RegistrationState['registration']>,
  ) => assign('registration', registration),

  setDraftRegistration: (
    draft: NonNullable<RegistrationState['draftRegistration']>,
  ) => assign('draftRegistration', draft),

  clearDraftRegistration: () => {
    assign('draftRegistration', null);
  },

  init: (settings: RegistrationState['settings']) => {
    // state is already initialized just update settings -- really for testing
    if (state) {
      state.settings = settings;
      return;
    }

    const storedState = registrationStorage.retrieve();

    state =
      storedState ?
        { ...storedState, settings, registration: null }
      : createInitialState(settings);

    notifyChange(state);
  },
} as const;

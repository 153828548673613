import { useRouteLoaderData } from 'react-router-dom';
import { z } from 'zod';
import { GspContext } from '~/stores/gsp';
import api from '~/utils/api';
import { computeCost, sumBy } from '~/utils/compute';
import { formatDate, toCurrency } from '~/utils/formatters';
import {
  createLoaderDataHook,
  gspLoader,
  json,
  redirect,
} from '~/utils/routing';
import { AsyncReturnType } from '~/utils/types';

type Order =
  | AsyncReturnType<typeof api.fetchProtectionOrder>
  | NonNullable<GspContext['order']>;

export type LoaderData = {
  store: string;
  orderNo: string;
  orderDate: string;
  price: string;
  postalCode?: string;
  wasShippingProtected: boolean;
};

export type ProtectionOrderData = {
  order: Order;
};

export const ID = 'protectionOrder';

const getLatestClaim = (claims: NonNullable<Order['shippingClaims']>) => {
  const sorted = claims.sort((a, b) => b.createdOn.localeCompare(a.createdOn));

  return sorted[0];
};

const checkOrderStore = (store: string) => (order: Order) =>
  store === '' || order.storeSlug === store ?
    Promise.resolve(order)
  : Promise.reject(new Error('Order not from store'));

// * This is to keep both loaders from creating multiple requests for the order
let orderRequest: Promise<Order> | null = null;
const getOrder = ({
  context,
  query,
  store,
}: {
  context: GspContext;
  query: { idFromPlatform: string };
  store: string;
}) => {
  if (orderRequest) {
    return orderRequest;
  }

  orderRequest =
    context.order?.idFromPlatform === query.idFromPlatform ?
      Promise.resolve(context.order)
    : api.fetchProtectionOrder({ query });

  return (
    orderRequest
      // ! ensure that the order is from the store we are on if we are on a branded reorder path
      .then(checkOrderStore(store))
      .then((order) => {
        context.setOrder(order);
        return order;
      })
      .finally(() => {
        orderRequest = null;
      })
  );
};

const paramsSchema = z.object({
  idFromPlatform: z.string(),
  store: z.string().default(''),
});

// this will provide the order data to the AppShell component
export const protectionOrderLoader = gspLoader(({ params, context }) => {
  const { idFromPlatform, store } = paramsSchema.parse(params);

  return getOrder({ context, query: { idFromPlatform }, store })
    .then((order) =>
      json<ProtectionOrderData>({
        order,
      }),
    )
    .catch(() => redirect(`/${store}`));
});
/**
 * This hook uses the `useRouteLoaderData` hook to provide
 * the order data any ancestor or descendant components
 * matching the `Reorder` route. In particular, it provides
 * the order data to the AppShell rather than having the AppShell query
 * the order data itself.
 */
export const useProtectionOrderData = () =>
  useRouteLoaderData(ID) as ProtectionOrderData | undefined;

/**
 *  This loader provides the data needed by the OrderOverview component
 *  We  use this instead of th `useProtectionOrderData` hook to provide the data
 *  so that the redirects are checked each time the page is loaded
 */
export const orderOverviewLoader = gspLoader(({ params, context }) => {
  const { idFromPlatform, store } = paramsSchema.parse(params);
  return getOrder({ context, query: { idFromPlatform }, store })
    .then((order) => {
      if (order.shippingClaims?.length) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked that claims is not null and has length
        const claim = getLatestClaim(order.shippingClaims)!;
        return redirect(`claim/${claim.id}/existing`);
      }

      const allowShippingClaimCreate =
        order.wasShippingProtected || order.isUnprotectedShippingClaimPermitted;

      if (!allowShippingClaimCreate) {
        return redirect('not-protected');
      }

      return json<LoaderData>({
        store: order.storeName,
        orderNo: order.orderNo,
        orderDate: formatDate(order.createdOn),
        price: toCurrency(order.currencyCode)(
          sumBy(computeCost)(order.lineItems),
        ),

        postalCode: order.shippingAddress?.postalCode,
        wasShippingProtected: order.wasShippingProtected,
      });
    })
    .catch(() => redirect(`/${store}`));
});

/**
 * This hook provides the data needed by the OrderOverview component
 */
export const useOrderOverviewLoaderData = createLoaderDataHook<LoaderData>();

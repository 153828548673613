import type { RouteObject } from 'react-router-dom';
import Spinner from '~/components/spinner';
import { catchallRoute } from '~/utils/routing';
import CrewLookup from './[:store]';
import Component from './app-shell-component';
import loader from './app-shell-loader';
import ErrorPage from './error';
import Reorder from './reorder';
import GspLookup from './root';
import themeLoader from './theme-loader';

export default {
  ErrorBoundary: ErrorPage,
  HydrateFallback: Spinner, // needed to silence warnings
  loader: themeLoader,
  // only run the loader once on initial load to only set the theme once
  shouldRevalidate: () => false,
  children: [
    {
      id: 'appShell',
      Component,
      loader,
      children: [CrewLookup, GspLookup, Reorder, catchallRoute],
    },
    catchallRoute,
  ],
} satisfies RouteObject;

import { StatusCodes } from 'http-status-codes';
import { z } from 'zod';
import { zfd } from 'zod-form-data';
import {
  createActionResultHook,
  crewAction,
  error,
  json,
  redirect,
  withDraftClaim,
} from '~/utils/routing';
import {
  draftLineItemClaimSchema,
  mediaAssetSchema,
  stringToJSONSchema,
} from '~/utils/schemas';
import { ActionResult } from '~/utils/types';

export enum FormKeys {
  Comments = 'comments',
  Assets = 'assets',
  RequiredAssetCount = 'requiredAssetCount',
}

const schema = zfd.formData(
  z
    .object({
      [FormKeys.Comments]: zfd
        .text(z.string().optional())
        .transform((value) => value ?? null),
      [FormKeys.RequiredAssetCount]: zfd.numeric(
        z.number().int().nonnegative(),
      ),
      [FormKeys.Assets]: zfd.repeatableOfType(
        stringToJSONSchema.pipe(mediaAssetSchema),
      ),
    })
    .catchall(draftLineItemClaimSchema.shape.customFields.valueSchema),
);

export default crewAction(
  withDraftClaim(({ formData, claim, context }) => {
    const results = schema.safeParse(formData);

    if (!results.success) {
      const formInfo = Object.fromEntries([...formData.entries()]) as Record<
        string,
        unknown
      >;

      return error(
        new Error('Malformed form data', {
          cause: {
            error: results.error,
            formInfo,
          },
        }),
      );
    }

    const {
      [FormKeys.Comments]: comments,
      [FormKeys.Assets]: assets,
      files,
      [FormKeys.RequiredAssetCount]: requiredAssetCount,
      ...customFields
    } = results.data;

    const assetsRequired = requiredAssetCount > 0;

    if (assetsRequired && assets.length < requiredAssetCount) {
      return json<ActionResult<FormKeys>>({
        ok: false,
        form: {
          [FormKeys.Assets]: `A minimum of ${requiredAssetCount} media ${requiredAssetCount === 1 ? 'file is' : 'files are'} required.`,
        },
      });
    }

    context.setDraftClaim({
      ...claim,
      customFields,
      comments,
      assets,
    });

    return redirect('../resolution', StatusCodes.SEE_OTHER);
  }),
);

export const useMetaActionResult =
  createActionResultHook<ActionResult<FormKeys>>();

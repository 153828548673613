import { z } from 'zod';
import { GspClaimReason } from '~/utils/constants';
// import { GspContext, GspState } from '~/utils/types';
import {
  gspOrderSchema,
  mediaAssetSchema,
  shippingClaimSchema,
} from '~/utils/schemas';
import { createSessionStorageClient } from '~/utils/session-storage-client';
import { Simplify } from '~/utils/types';

const gspStateSchema = z.object({
  order: gspOrderSchema.nullable(),
  reason: z.nativeEnum(GspClaimReason).nullable(),
  itemIds: z.array(gspOrderSchema.shape.lineItems.element.shape.id).nullable(),
  meta: z
    .object({
      noteFromCustomer: shippingClaimSchema.shape.noteFromCustomer.optional(),
      assets: z.array(mediaAssetSchema).optional(),
    })
    .nullable(),
});

export type GspState = z.infer<typeof gspStateSchema>;

export type GspContext = Simplify<
  GspState & {
    reset: () => void;
    setOrder: (
      order: NonNullable<GspState['order']>,
    ) => NonNullable<GspState['order']>;
    setReason: (
      reason: NonNullable<GspState['reason']>,
    ) => NonNullable<GspState['reason']>;
    setItemIds: (
      items: NonNullable<GspState['itemIds']>,
    ) => NonNullable<GspState['itemIds']>;
    setMeta: (
      meta: NonNullable<GspState['meta']>,
    ) => NonNullable<GspState['meta']>;
    clearOrder: () => void;
    clearMeta: () => void;
    clearReason: () => void;
    clearItemIds: () => void;
  }
>;

const gspStorage = createSessionStorageClient(
  'corso-gsp-state',
  gspStateSchema,
);
const defaultState: GspState = {
  order: null,
  reason: null,
  itemIds: null,
  meta: null,
};
const storedState = gspStorage.retrieve();

let state: GspState = storedState ?? { ...defaultState };

const notifyChange = (newState: GspState) => {
  gspStorage.store(newState);
};

const assign = <K extends keyof GspState, V extends GspState[K]>(
  key: K,
  value: V,
) => {
  state = { ...state, [key]: value };

  notifyChange(state);

  return value;
};

export const GspStore: GspContext = {
  get order() {
    return state.order;
  },
  get reason() {
    return state.reason;
  },
  get itemIds() {
    return state.itemIds;
  },
  get meta() {
    return state.meta;
  },

  setOrder: (order) => assign('order', order),
  setReason: (reason) => assign('reason', reason),
  setItemIds: (ids) => assign('itemIds', ids),
  setMeta: (meta) => assign('meta', meta),
  clearOrder: () => assign('order', null),
  clearReason: () => assign('reason', null),
  clearItemIds: () => assign('itemIds', null),
  clearMeta: () => assign('meta', null),

  reset: () => {
    state = { ...defaultState };

    notifyChange(state);
  },
};
